export const PROJECT_NAME = 'Ootewe';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://oteewe-api.skytechno.web.id';
export const WEBSITE_URL = 'https://oteewe.skytechno.web.id';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyB8Xfq-jUM011yjbsdhfy3DFYbsOYKe3CM';
export const export_url = BASE_URL + '/storage/';
export const example = BASE_URL + '/';

export const VAPID_KEY =
  'BA6q8mRWOAT79bg5KK6GzsknbsY1fjGh-cGgsuf23U8K2MwGoAuifKO_LKwkzKFrL1ba6ishr-2-FdLUfvQMQdQ';

  export const LAT = 0.728760;
  export const LNG = 121.647920;

export const API_KEY = 'AIzaSyA8f5Tl7yGbyA4Mwupvf3VElmxqhjtr9nA';
export const AUTH_DOMAIN = 'oontheway-28841.firebaseapp.com';
export const PROJECT_ID = 'oontheway-28841';
export const STORAGE_BUCKET = 'oontheway-28841.appspot.com';
export const MESSAGING_SENDER_ID = '157616847277';
export const APP_ID = '1:157616847277:web:cdd5c16b2dc30971bb4924';
export const MEASUREMENT_ID = 'G-668E04YSFS';

export const RECAPTCHASITEKEY = '6LeD1jsmAAAAAC4gC1re2fPI7Z0Due8htS7oXNWa';

export const DEMO_SELLER = 334; // seller_id
export const DEMO_SELLER_UUID = '3566bdf6-3a09-4488-8269-70a19f871bd0'; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];



